const mainHeader = document.querySelector('.fp-main-header')
const headerHeight = document
  .querySelector('.fp-header')
  .getBoundingClientRect().height
const headerWidth = document
  .querySelector('.fp-header')
  .getBoundingClientRect().width

const header = document.querySelector('.fp-header__sticky')
const headerResponsive = document.querySelector('.fp-header-responsive')
const headerResponsiveHeight = headerResponsive.getBoundingClientRect().height
const mobileView = window.matchMedia('(max-width: 900px)')
const topHeaderHeight = document
  .querySelector('.fp-header__sticky')
  .getBoundingClientRect().height
let lastScroll = 0
const topMenu = document
  .querySelector('.fp-main-header__top')
  .getBoundingClientRect().height
const breakingNews = document.querySelector(
  '[data-fp-container-name="breaking-news-header"]',
)

const breakingNewsInitialY = breakingNews?.getBoundingClientRect()?.top

const setPosition = () => {
  const headerWidth = document
    .querySelector('.fp-header')
    .getBoundingClientRect().width
  header.style.setProperty('width', `${headerWidth}px`)
}

const stickyHeader = () => {
  const currentScroll = window.pageYOffset
  // Responsive
  if (mobileView.matches) {
    // Reset responsive header
    if (currentScroll < headerResponsiveHeight) {
      headerResponsive.classList.remove('scroll-up')
      headerResponsive.classList.remove('scroll-down')

      return
    }

    // Scrolling down
    if (currentScroll > lastScroll) {
      // If scrolling down and there is no scroll-down class on the responsive header, add it
      if (!headerResponsive.classList.contains('scroll-down')) {
        headerResponsive.classList.remove('scroll-up')
        headerResponsive.classList.add('scroll-down')
      }

      if (breakingNews && currentScroll > breakingNewsInitialY - 200) {
        breakingNews?.style?.setProperty('top', `0px`)
      }
    }

    // Scrolling uo
    if (currentScroll < lastScroll) {
      // If scrolling up and there is scroll-down class, remove it and add scroll-up class
      if (headerResponsive.classList.contains('scroll-down')) {
        headerResponsive.classList.remove('scroll-down')
        headerResponsive.classList.add('scroll-up')
        breakingNews?.style?.setProperty('top', `102px`)
      }

      if (breakingNews && currentScroll < breakingNewsInitialY - 250) {
        breakingNews?.style?.setProperty('top', `0px`)
      }
    }
  } else {
    // DESKTOP HEADER

    // Reset header
    if (currentScroll <= topMenu) {
      header.classList.remove('scroll-up')
      header.classList.remove('scroll-down')
      header.classList.remove('initial-scroll-down')
      return
    }

    // Scrolling up
    if (currentScroll < lastScroll) {
      if (
        header.classList.contains('scroll-down') ||
        header.classList.contains('initial-scroll-down')
      ) {
        breakingNews?.style?.setProperty('top', `102px`)
        header.classList.remove('scroll-down')
        header.classList.remove('initial-scroll-down')
        header.classList.add('scroll-up')
        header.style.setProperty('padding-top', '0px')
      }

      if (breakingNews && currentScroll < breakingNewsInitialY - 250) {
        breakingNews?.style?.setProperty('top', `0px`)
      }
    }

    // Scrolling down
    if (currentScroll > lastScroll) {
      if (breakingNews && currentScroll > breakingNewsInitialY - 200) {
        breakingNews?.style?.setProperty('top', `0px`)
      }

      if (currentScroll > headerHeight * 2) {
        header.classList.remove('initial-scroll-down')
        header.classList.remove('scroll-up')
        header.classList.add('scroll-down')
      }

      if (
        !header.classList.contains('scroll-down') &&
        !header.classList.contains('scroll-up') &&
        currentScroll > headerHeight
      ) {
        header.classList.add('initial-scroll-down')
      }

      if (
        !header.classList.contains('scroll-down') &&
        header.classList.contains('scroll-up') &&
        currentScroll > headerHeight
      ) {
        header.classList.remove('scroll-up')
        header.classList.remove('initial-scroll-down')
        header.classList.add('scroll-down')
      }
    }
  }

  lastScroll = currentScroll
}

window.addEventListener('load', setPosition)
window.addEventListener('resize', setPosition)
window.addEventListener('scroll', stickyHeader)
