const sideMenuContainer = document.querySelector('.fp-sidebar-menu')
const sideMenuOverlay = document.querySelector('.fp-sidebar-menu__overlay')
const openSideMenuButton = document.querySelector(
  '.fp-main-header__menu-search-wrapper--burger',
)
const headerSearchIcon = document.querySelector(
  '.fp-main-header__menu-search-wrapper--search',
)
const openSideMenuButtonResponsive = document.querySelector(
  '.fp-header-responsive__top .fp-main-header__menu-search-wrapper--burger',
)
const closeSideMenuButton = document.querySelector(
  '.fp-sidebar-menu__header--close',
)

const initSideMenu = () => {
  sideMenuContainer.classList.add('closed')
  sideMenuOverlay.classList.add('closed')
}

const openSideMenu = () => {
  sideMenuContainer.classList.add('open')
  sideMenuOverlay.classList.add('open')
  sideMenuContainer.classList.remove('closed')
  sideMenuOverlay.classList.remove('closed')
  document.body.classList.add('overflow-hidden')
  document.body.classList.add('sidebar-width')
}
const closeSideMenu = () => {
  sideMenuContainer.classList.remove('open')
  sideMenuOverlay.classList.remove('open')
  sideMenuContainer.classList.add('closed')
  sideMenuOverlay.classList.add('closed')
  document.body.classList.remove('overflow-hidden')
  document.body.classList.remove('sidebar-width')
}
const escapeSideMenu = e => {
  if (e.key === 'Escape') {
    sideMenuContainer.classList.remove('open')
    sideMenuOverlay.classList.remove('open')
    sideMenuContainer.classList.add('closed')
    sideMenuOverlay.classList.add('closed')
    document.body.classList.remove('overflow-hidden')
    document.body.classList.remove('sidebar-width')
  }
}

document.addEventListener('DOMContentLoaded', initSideMenu)

openSideMenuButton && openSideMenuButton.addEventListener('click', openSideMenu)

openSideMenuButtonResponsive &&
  openSideMenuButtonResponsive.addEventListener('click', openSideMenu)

closeSideMenuButton &&
  closeSideMenuButton.addEventListener('click', closeSideMenu)

document.body.addEventListener('keyup', escapeSideMenu)

sideMenuOverlay && sideMenuOverlay.addEventListener('click', closeSideMenu)
