// Main Navigation Hover & Active state
window.addEventListener('load', event => {
  let navElements = Array.from(document.getElementsByTagName('nav')).filter(
    x => x.dataset.id === 'js-nav',
  )
  let pointers = Array.from(document.getElementsByTagName('div')).filter(
    x => x.dataset.id === 'js-pointer',
  )
  let linksGroup = navElements.map(navElement =>
    navElement.getElementsByTagName('li'),
  )
  let linkActive = document.querySelector('li.active-list-item')

  const startInfo = () => {
    if (linkActive) {
      let position = { top: linkActive.offsetTop, left: linkActive.offsetLeft }
      let startWidth = linkActive.getBoundingClientRect().width
      pointers.forEach(pointer => {
        pointer.style.transform =
          'translate3d(' + position.left + 'px' + ',0,0)'
        pointer.style.width = startWidth + 'px'
      })
    } else {
      pointers.forEach(pointer => {
        pointer.style.width = 0 + 'px'
      })
    }
  }

  for (const links of linksGroup) {
    for (let i = 0; i < links.length; i++) {
      let current = links[i]
      current.dataset.order = i * 100 + '%'
      current.addEventListener('mouseenter', movePointer)
    }
  }

  function movePointer(e) {
    let order = e.currentTarget.dataset.order
    pointers.forEach(pointer => {
      pointer.style.transform =
        'translate3d(' + this.offsetLeft + 'px' + ',0,0)'
      pointer.style.width = this.getBoundingClientRect().width + 'px'
    })
  }

  startInfo()
  navElements.forEach(nav => nav.addEventListener('mouseleave', startInfo))

  let dropBtn = document.getElementById('fp-main-header__links-wrapper-mobile')
  let dropDown = document.getElementById(
    'fp-main-header__links--login-dropdown',
  )
  function openDropDown(e) {
    if (dropDown.classList.contains('open')) {
      dropDown.classList.remove('open')
    } else {
      dropDown.classList.add('open')
    }
  }

  if (dropBtn) {
    dropBtn.addEventListener('click', openDropDown)
  }
})
