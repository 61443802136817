const select = document.querySelectorAll('.fp-select select')

const icnCheck = `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 3L4.5 8.5L2 6" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`

select.forEach(s => {
  // Cache each select children
  const selectOptions = s.children

  // Cache the styled select display
  const styledSelect = s.parentElement.querySelector('.fp-select__styled')
  const styledSelectDisplay = s.parentElement.querySelector(
    '.fp-select__styled p',
  )

  // Convert HTML collection into array
  const optionsArray = Array.from(selectOptions)

  // Show first selected option
  if (s.id === 'tagSelector') {
    let options = optionsArray.map(option => ({
      text: option.text,
      value: option.value,
    }))
    const urlPath = window.location.href.split('/')
    let selectDisplayHasValue = false

    options.forEach(option => {
      if (option.value === `/${urlPath[urlPath.length - 1].toLowerCase()}`) {
        styledSelectDisplay.innerHTML = option.text
        selectDisplayHasValue = true
      }
    })
    if (!selectDisplayHasValue) {
      styledSelectDisplay.innerHTML = selectOptions[0].text
    }
  } else {
    styledSelectDisplay.innerHTML = selectOptions[0].text
  }

  // Cache the styled select dropdown
  const styledSelectOptions = s.parentElement.querySelector(
    '.fp-select__styled--options',
  )

  // Render the list of options into the dropdown
  const renderedOptions = optionsArray
    .map(
      option =>
        `<li><p class="text-12--bold" data-value="${option.value}">${option.text}</p> ${icnCheck}</li>`,
    )
    .join('')

  styledSelectOptions.querySelector('ul').innerHTML = `${renderedOptions}`

  // Open dropdown function
  const openDropdown = e => {
    e.stopPropagation()
    styledSelectOptions.style.setProperty('display', 'block')
  }

  // Close dropdown function
  const closeDropdown = () => {
    styledSelectOptions.style.setProperty('display', 'none')
  }

  // Close dropdown function with escape key
  const escapeDropdown = e => {
    if (e.key === 'Escape') {
      closeDropdown()
    }
  }

  // Open the dropdown menu
  styledSelect.addEventListener('click', openDropdown)

  // Cache the list items
  const styledOptions = s.parentElement.querySelectorAll(
    '.fp-select__styled--options li',
  )

  // Cache the checkmarks
  const checkmarks = s.parentElement.querySelectorAll(
    '.fp-select__styled--options li svg',
  )

  // Handle option click function
  function handleOptionClick(evt, parentId) {
    const { currentTarget } = evt
    const selectedValue = currentTarget
      .querySelector('p')
      .getAttribute('data-value')

    styledSelectDisplay.innerHTML = optionsArray.find(
      x => x.value === selectedValue,
    ).text

    checkmarks.forEach(checkmark =>
      checkmark.style.setProperty('visibility', 'hidden'),
    )

    currentTarget
      .querySelector('svg')
      .style.setProperty('visibility', 'visible')

    if (parentId === 'tagSelectList') {
      window.location.href = `/lokal${selectedValue}`
    }

    closeDropdown()
  }

  // Select from options and close the menu
  styledOptions.forEach((option, index) => {
    const parentId = option.parentElement.id
    option.querySelector('svg').style.setProperty('visibility', 'hidden')
    const currentOptionValue = option.querySelector('p').innerHTML

    if (s.id === 'tagSelector') {
      if (currentOptionValue === styledSelectDisplay.innerHTML) {
        checkmarks[index].style.setProperty('visibility', 'visible')
      }
    } else {
      checkmarks[0].style.setProperty('visibility', 'visible')
    }

    option.addEventListener('click', evt => handleOptionClick(evt, parentId))
  })

  // Close menu when clicking outside and hitting Escape on keyboard
  document.body.addEventListener('click', closeDropdown)
  document.body.addEventListener('keyup', escapeDropdown)
})
