// Here we import all the scripts

require('./user_session')
require('./bineos_article_template')
require('lazysizes')
require('./accordion')
require('./add_offering_submit_handle')
require('./header_search')
require('./dropdown')
require('./side_menu')
require('./regions_dropdown')
require('./newsletter')
require('./breaking_news')
require('./search_results_page_search')
require('./source_point_functions')
require('./top_navigation_slider')
require('./main_navigations')
require('./sticky_header')
require('./cmp_footer_links')
require('./show_hide_login_buttons')
require('./append_redirect_urls_login_links')
require('./taboola_adjustments')
require('./bineos')
