const newsletter = document.querySelectorAll('.fp-newsletter')

newsletter.forEach(group => {
  const newsletterInput = group.querySelector('.fp-newsletter__input')
  const newsletterClose = group.querySelector('.fp-newsletter__close')

  const handleNewsletterForm = () => {
    if (newsletterInput.value.length > 0) {
      newsletterClose.classList.add('visible')
    } else {
      newsletterInput.value = ''
      newsletterClose.classList.remove('visible')
    }

    newsletterClose.addEventListener('click', () => {
      newsletterInput.value = ''
      newsletterClose.classList.remove('visible')
    })
  }

  newsletterInput.addEventListener('keyup', handleNewsletterForm)
})
