const { CUSTOM_EVENTS } = require('../constants/custom_events')

window.addEventListener(CUSTOM_EVENTS.UserLoginStatusUpdate, event => {
  const headerLoginButtons = Array.from(
    document.querySelectorAll('.header-login-button'),
  )
  const responsiveHeaderLoginButton = document.getElementById(
    'responsive-header-login-button',
  )
  const responsiveHeaderLoginNfButton = document.getElementById(
    'responsive-header-login-nf-button',
  )
  const loginButton = document.getElementById('fp-main-header__login-button')
  const logoutButton = document.getElementById('fp-main-header__logout-button')
  const loginButtonMobile = document.getElementById(
    'fp-main-header__login-button-mobile',
  )
  const logoutButtonMobile = document.getElementById(
    'fp-main-header__logout-button-mobile',
  )
  const loggInIconChange = document.getElementById('fp-main-header')

  const headerLogoutButtons = Array.from(
    document.querySelectorAll('.header-logout-button'),
  )
  const responsiveHeaderLogoutButton = document.getElementById(
    'responsive-header-logout-button',
  )
  const responsiveHeaderLogoutNfButton = document.getElementById(
    'responsive-header-logout-nf-button',
  )
  const sidebarLoginButton = document.getElementById('sidebar-login-button')
  const sidebarLogoutButton = document.getElementById('sidebar-logout-button')

  const sidebarMobileLoginButton = document.getElementById(
    'sidebar-mobile-login-button',
  )
  const sidebarMobileLogoutButton = document.getElementById(
    'sidebar-mobile-logout-button',
  )

  const removeButton = (mainButtonElement, oppositeButtonElement) => {
    if (mainButtonElement !== null && oppositeButtonElement !== null) {
      mainButtonElement.remove()
      oppositeButtonElement.classList.remove('fp-hidden')
    }
  }

  const removeButtons = (mainButtons, oppositeButtons) => {
    mainButtons.forEach(mainButtonElement => mainButtonElement.remove())
    oppositeButtons.forEach(oppositeButtonElement =>
      oppositeButtonElement.classList.remove('fp-hidden'),
    )
  }

  const handleLoginLogout = loginStatus => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: loginStatus ? 'loggedIn' : 'loggedOut' })
    }
    // If the user is loggedIn we remove the login button, otherwise we remove the logout button
    if (loginStatus) {
      // If the user is logged in are remove the logout icon and show the login button
      removeButton(loginButton, logoutButton)

      removeButton(loginButtonMobile, logoutButtonMobile)

      removeButtons(headerLoginButtons, headerLogoutButtons)
      // Remove the login button from the responsive header
      removeButton(responsiveHeaderLoginButton, responsiveHeaderLogoutButton)
      removeButton(
        responsiveHeaderLoginNfButton,
        responsiveHeaderLogoutNfButton,
      )
      // Remove the login button from the sidebar menu
      removeButton(sidebarLoginButton, sidebarLogoutButton)

      removeButton(sidebarMobileLoginButton, sidebarMobileLogoutButton)

      // If the user is logged in we add color to the icon to indicate logged in status
      if (loggInIconChange) {
        loggInIconChange.classList.add('logged-in')
        loginButton.classList.remove('fp-hidden')
      }
    } else {
      // If the user is logged out we remove the login button and show the logout button
      removeButton(logoutButton, loginButton)

      removeButton(logoutButtonMobile, loginButtonMobile)

      removeButtons(headerLogoutButtons, headerLoginButtons)
      // Remove the logout button from the responsive header
      removeButton(responsiveHeaderLogoutButton, responsiveHeaderLoginButton)
      removeButton(
        responsiveHeaderLogoutNfButton,
        responsiveHeaderLoginNfButton,
      )
      // Remove the logout button from the sidebar menu
      removeButton(sidebarLogoutButton, sidebarLoginButton)

      removeButton(sidebarMobileLogoutButton, sidebarMobileLoginButton)

      // If the user is logged out we remove color from the icon to indicate logged out status
      if (loggInIconChange) {
        loggInIconChange.classList.remove('logged-in')
      }
    }
  }

  handleLoginLogout(event.detail.isUserLoggedIn)
})
