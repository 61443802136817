const { CUSTOM_EVENTS } = require('../constants/custom_events')
const { tryInitBineos } = require('./helpers/bineos_functions')

const createUserLoginStatusEvent = isUserLoggedIn =>
  new CustomEvent(CUSTOM_EVENTS.UserLoginStatusUpdate, {
    bubbles: true,
    detail: {
      isUserLoggedIn,
    },
  })

const fetchUserLoginStatus = () => {
  // When we login, we have a request header C1-Session-ID. We ask the delivery for the existence of this header.
  // If it exists, then we know we are logged in.
  return fetch('/api/public/user-session')
    .then(res => res.json())
    .catch(err => {
      console.error(err)

      // In case there is a problem with the API call, we assume the user isn't logged in.
      // This will allow the script depending on the emitting of the "logged in" event
      // to run.
      return { isLoggedIn: false }
    })
}

const verifyUserLoginStatus = () => {
  return fetchUserLoginStatus().then(data => {
    const { isLoggedIn, subscriptions, id } = data

    // dispatch event about user status that will update the UI places that are different based on it
    const userLoginStatusEvent = createUserLoginStatusEvent(isLoggedIn)
    window.dispatchEvent(userLoginStatusEvent)

    // if we have subscriptions it means that the data comes from nf, so we should do all the staff previously done by c1-client-tracking
    if (subscriptions) {
      const { userType = 'pur-anon', permissions = [] } = subscriptions

      // handle SourcePoint, i.e. the PUR Subscription product
      if (typeof initiateSourcePoint === 'function') {
        // eslint-disable-next-line no-undef
        initiateSourcePoint(subscriptions)
      } else {
        console.error('Did not find SourcePoint integration')
      }

      // handle Bineos
      const ssoDataLayer = {
        id,
        permissions: Object.fromEntries(
          permissions.map(({ permission, ...rest }) => [permission, rest]),
        ), // bineos doesn't accept an array but a map with keys the permission id
      }
      tryInitBineos(userType, ssoDataLayer)
    } else {
      if (typeof initiateSourcePoint === 'function') {
        // eslint-disable-next-line no-undef
        initiateSourcePoint()
      } else {
        console.error('Did not find SourcePoint integration')
      }
    }
  })
}

// As soon as the DOM is parsed and ready, fetch user login status
window.addEventListener('DOMContentLoaded', () => {
  const enableSSO = document.documentElement.getAttribute('data-enable-sso')

  if (['true', true].includes(enableSSO)) {
    verifyUserLoginStatus().catch(error => console.error(error))
  }
  // No SSO: no login function (user logged out)
  else {
    const userLoginStatusEvent = createUserLoginStatusEvent(false)
    window.dispatchEvent(userLoginStatusEvent)
  }
})
