const headerLoginButtons = Array.from(
  document.querySelectorAll('.header-login-button'),
)
const responsiveHeaderLoginButton = document.getElementById(
  'responsive-header-login-button',
)
const responsiveHeaderLoginNfButton = document.getElementById(
  'responsive-header-login-nf-button',
)

const sidebarLoginButton = document.getElementById('sidebar-login-button')

const appendRedirectToLoginHref = button => {
  const oldHref = button.href
  const redirectUrl = encodeURIComponent(
    location.href.replace('?reduced=true', '').replace('http:', 'https:'),
  )
  if (oldHref && !oldHref.includes(`redirect=${redirectUrl}`)) {
    button.href = `${oldHref}?redirect=${redirectUrl}`
  }
}

headerLoginButtons.forEach(headerLoginButton =>
  appendRedirectToLoginHref(headerLoginButton),
)
if (responsiveHeaderLoginButton) {
  appendRedirectToLoginHref(responsiveHeaderLoginButton)
}
if (responsiveHeaderLoginNfButton) {
  appendRedirectToLoginHref(responsiveHeaderLoginNfButton)
}
if (sidebarLoginButton) {
  const loginLink = document.getElementById('sidebar-login-button')
    .childNodes[1]
  appendRedirectToLoginHref(loginLink)
}

document.querySelectorAll('li').forEach((item, index) => {
  if (
    ['abonnieren', 'register'].includes(item.childNodes[1].text?.toLowerCase())
  ) {
    appendRedirectToLoginHref(item.childNodes[1])
  }
})
