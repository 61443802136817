const local = document.querySelector(
  '.fp-header-responsive__bottom .fp-main-nav__local',
)
const regionsToggle = local?.querySelector('.fp-main-nav__local__toggle')
const regionsDropdown = local?.querySelector('.fp-main-nav__local__dropdown')
let dropdownIsOpen = false

const toggleRegionsDropdown = () => {
  if (!dropdownIsOpen) {
    regionsToggle.classList.add('open')
    regionsDropdown.classList.add('open')
    dropdownIsOpen = !dropdownIsOpen
  } else {
    regionsToggle.classList.remove('open')
    regionsDropdown.classList.remove('open')
    dropdownIsOpen = !dropdownIsOpen
  }
}

regionsToggle?.addEventListener('click', toggleRegionsDropdown)
