const { tryInitBineos } = require('./helpers/bineos_functions')
const { LOCAL_STORAGE_KEYS } = require('../constants/local_storage_keys')
const { CUSTOM_EVENTS } = require('../constants/custom_events')

// Delete the user type from the local storage before exiting page
window.addEventListener('beforeunload', event => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.C1UserType)
})

// Try reading user type from local storage, in case it has already been stored
let userType = localStorage.getItem(LOCAL_STORAGE_KEYS.C1UserType)

// If it already exists, start initializing Bineos
if (userType) {
  tryInitBineos(userType)
}

// Otherwise, wait until it has been loaded from C1
else {
  window.addEventListener(CUSTOM_EVENTS.UserTypeStorage, event => {
    userType = localStorage.getItem(LOCAL_STORAGE_KEYS.C1UserType)
    tryInitBineos(userType)
  })
}
