function createScript(innerHTML) {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = innerHTML

  return script
}

function getTaboolaHeadScript(url) {
  return createScript(`
    window._taboola = window._taboola || []
    
    window._taboola.push({
      article: 'auto',
    })
      
    function consentEnableTaboola() { 
       
      !(function (e, f, u, i) {
        if (!document.getElementById(i)) {
          e.async = 1
          e.src = u
          e.id = i
          f.parentNode.insertBefore(e, f)
        }
      })(
        document.createElement('script'),
        document.getElementsByTagName('script')[0],
        '${url}',
        'tb_loader_script',
      )
    
      if (window.performance && typeof window.performance.mark == 'function') {
        window.performance.mark('tbl_ic')
      }
    }
  `)
}

function getTaboolaEndOfArticleScript(isRevShare) {
  let placement = 'Feed Below Article Thumbnails'
  if (isRevShare) {
    placement = `${placement} RS`
  }

  return createScript(`
    window._taboola = window._taboola || []

    window._taboola.push({
      mode: 'thumbs-feed-01',
      container: 'taboola-feed-below-article-thumbnails',
      placement: '${placement}',
      target_type: 'mix',
    })
  `)
}

function getTaboolaFlushScript() {
  return createScript(`
    window._taboola = window._taboola || []

    window._taboola.push({
      flush: true,
    })
  `)
}

module.exports = {
  getTaboolaHeadScript,
  getTaboolaEndOfArticleScript,
  getTaboolaFlushScript,
}
