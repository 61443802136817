// Navigation Slider Functionality
const navContainer = document.querySelector('.fp-top-nav')
const navList = document.querySelector('.fp-top-nav__list')

if (navContainer != null && navList != null) {
  const navArrowContainer = document.querySelector(
    '.fp-top-nav__arrow-container',
  )
  const navArrowLeft = document.querySelector('.fp-top-nav__left-arrow')
  const navArrowRight = document.querySelector('.fp-top-nav__right-arrow')
  const navArrowContainerWidth = 48
  let slideInitial = 0
  let slideAmount = 30

  // Set initial value to the navList
  navList.style.setProperty('transform', `translate(${slideInitial}px, 0px)`)
  const calculateNavListWidth = () => {
    const navContainerCoords = navContainer.getBoundingClientRect()
    const navListCoords = navList.getBoundingClientRect()
    if (
      navListCoords.width + navArrowContainerWidth >
      navContainerCoords.width
    ) {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'flex')
    } else {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'none')
      navList.style.setProperty('transform', `translate(0px, 0px`)
    }
  }
  const slideNavLeft = () => {
    const navListCoords = navList.getBoundingClientRect()
    const navContainerCoords = navContainer.getBoundingClientRect()
    if (
      !(navListCoords.right + navArrowContainerWidth < navContainerCoords.right)
    ) {
      slideInitial = slideInitial - slideAmount
      navList.style.setProperty('transform', `translate(${slideInitial}px, 0px`)
    }
  }
  const slideNavRight = () => {
    if (navList.style.transform !== 'translate(0px, 0px)') {
      slideInitial = slideInitial + slideAmount
      navList.style.setProperty('transform', `translate(${slideInitial}px, 0px`)
    }
  }
  window.addEventListener('resize', calculateNavListWidth)
  calculateNavListWidth()
  navArrowLeft.addEventListener('click', slideNavRight)
  navArrowLeft.addEventListener('mousedown', slideNavRight)
  navArrowRight.addEventListener('click', slideNavLeft)
  navArrowRight.addEventListener('mousedown', slideNavLeft)
}
