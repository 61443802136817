;(function searchResultsPageSearch() {
  const searchPageInput = document.querySelector('.search-page-input-field')
  const searchPageButton = document.querySelector('.search-page-button')
  if (searchPageInput && searchPageButton) {
    const searchQuery = window.location.search
    const urlParams = new URLSearchParams(searchQuery)
    const searchQueryValue = urlParams.get('q')
    searchPageInput.value = searchQueryValue

    const baseSearchUrl = '/search'
    const constructSearchUri = query => `${baseSearchUrl}?q=${query}`

    const enterSearchPageSearch = e => {
      if ((e.key === 'Enter' || e.type === 'click') && searchPageInput.value) {
        openSearchPage(searchPageInput.value)
      }
    }

    const openSearchPage = searchValue => {
      const query = encodeURIComponent(searchValue)
      window.location.href = constructSearchUri(query)
    }

    searchPageInput &&
      searchPageInput.addEventListener('keyup', enterSearchPageSearch)

    searchPageButton &&
      searchPageButton.addEventListener('click', enterSearchPageSearch)
  }
})()
