const { CUSTOM_EVENTS } = require('../constants/custom_events')
const {
  getTaboolaHeadScript,
  getTaboolaEndOfArticleScript,
  getTaboolaFlushScript,
} = require('./helpers/taboola_scripts')

const ATTR_URL = 'data-url'
const ATTR_COMPLIANCE = 'data-compliance'
const ATTR_PAID = 'data-paid'

window.addEventListener(CUSTOM_EVENTS.UserLoginStatusUpdate, event => {
  const { isUserLoggedIn } = event.detail
  loadTaboolaScripts(isUserLoggedIn)
})

const loadTaboolaScripts = isUserLoggedIn => {
  const head = document.head
  const body = document.body
  const belowArticleElement = document.getElementById(
    'taboola-feed-below-article-thumbnails',
  )

  // Taboola scripts should only be injected when that 'div' with ID
  // 'taboola-feed-below-article-thumbnails' is in that article's body
  const isTaboolaEnabled = !!belowArticleElement
  if (!isTaboolaEnabled) {
    return
  }

  const url = belowArticleElement.getAttribute(ATTR_URL)
  const isArticleCompliant =
    belowArticleElement.getAttribute(ATTR_COMPLIANCE) === 'true'
  const isArticlePaid = belowArticleElement.getAttribute(ATTR_PAID) === 'true'

  const isRevShare = !isArticleCompliant || (isArticlePaid && !isUserLoggedIn)

  head.appendChild(getTaboolaHeadScript(url))
  belowArticleElement.after(getTaboolaEndOfArticleScript(isRevShare))
  body.appendChild(getTaboolaFlushScript())
}
