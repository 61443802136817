const getTouchpoint = contentType => {
  if (!contentType) {
    console.error('Missing content type!')
    return
  }

  // Articles
  if (contentType === 'article') {
    return 'article'
  }

  // Category/Sub-category pages
  if (['category-page', 'subcategory-page'].includes(contentType)) {
    return 'category'
  }

  // Video/Gallery/Audio Articles
  if (['video', 'gallery', 'audio'].includes(contentType)) {
    return contentType
  }

  // Homepage
  if (contentType === 'page') {
    return 'startpage'
  }

  // AGB, Impressum, Datenschutzerklärung
  if (contentType === 'general-page') {
    return 'static'
  }

  console.error(`Unknown touchpoint for content type: ${contentType}`)
}

const getCustomTopicId = (_category, _subCategory) => {
  const category = _category.replace('/', '')
  const subCategory = _subCategory.replace('/', '')

  let id = ''

  if (category) {
    id = category

    if (subCategory) {
      id += '/' + subCategory
    }
  }

  return id
}

const extractDetailsFromHTML = el => {
  const id = el.getAttribute('data-bineos-id')
  const documentId = el.getAttribute('data-document-id')
  const ivwId = el.getAttribute('data-ivw-id')
  const contentType = el.getAttribute('data-content-type')
  const category = el.getAttribute('data-category')
  const subCategory = el.getAttribute('data-sub-category')

  // Parse info sent by delivery server and return info needed for Bineos
  const touchpoint = getTouchpoint(contentType)
  const customTopicId = getCustomTopicId(category, subCategory)
  const articleId = ['article', 'video', 'gallery', 'audio'].includes(
    contentType,
  )
    ? documentId
    : ''

  // Build tracking infos object and only add attributes if they exist
  const trackingInfos = {}

  if (touchpoint) {
    trackingInfos.touchpoint = touchpoint
  }
  if (ivwId) {
    trackingInfos.ivwId = ivwId
  }
  if (customTopicId) {
    trackingInfos.customTopicId = customTopicId
  }
  if (articleId) {
    trackingInfos.articleId = articleId
  }

  return {
    id,
    trackingInfos,
  }
}

const tryInitBineos = (userType, ssoDataLayer) => {
  // If loading Bineos fails, the page should still load
  try {
    initBineos(userType, ssoDataLayer)
  } catch (err) {
    console.error(err)
  }
}

const initBineos = (userType, ssoDataLayer) => {
  const { Bineos } = window

  if (!Bineos) {
    throw new Error('Bineos client missing!')
  }

  // Extract necessary infos to enable Bineos tracking in user browser using
  // the 'div' element served by the deliveries
  const bineosElement = document.getElementById('bineos-tracking-infos')

  if (!bineosElement) {
    throw new Error('Bineos tracking infos missing!')
  }

  const { id, trackingInfos } = extractDetailsFromHTML(bineosElement)

  // Create new instance of Bineos client
  const bineos = new Bineos(id)

  // Define tracking infos
  bineos.set({
    ...trackingInfos,
    userStatus: userType,
  })

  if (ssoDataLayer) {
    bineos.dataLayer.sso = ssoDataLayer
  }

  // Send infos to Bineos server
  bineos.send()
}

module.exports = {
  tryInitBineos,
}
