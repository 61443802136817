// Dictionary of in-house custom events meant to help keeping track and managing
// the emitting and listening of such events.
const CUSTOM_EVENTS = {
  C1TrackingLoaded: 'C1_TRACKING_LOADED',
  UserLoginStatusUpdate: 'USER_LOGIN_STATUS_UPDATE',
  UserTypeStorage: 'USER_TYPE_STORAGE',
}

module.exports = {
  CUSTOM_EVENTS,
}
