// Header Search Functionality
const headerSearchContainer = document.querySelector('.fp-main-header-search')
const searchPageInput = document.querySelector('search-page-input-field')
const headerSearchInput = document.querySelector('#headerSearchInput')
const headerSearchButton = document.querySelector('#headerSearchButton')

const openHeaderSearchButton = document.querySelector(
  '.fp-main-header__menu-search-wrapper--search',
)

const closeHeaderSearchButton = document.querySelector(
  '.fp-main-header-search__close',
)

const sidebarSearchInput = document.querySelector('#sidebarSearchInput')
const sidebarSearchButton = document.querySelector('#sidebarSearchButton')
const sidebarSearchClose = document.querySelector(
  '.fp-sidebar-menu__search__close',
)

const baseSearchUrl = '/search'
const constructSearchUri = query =>
  `${baseSearchUrl}?q=${query.substring(0, 200)}`

const headerSearchOverlay = document.querySelector(
  '.fp-main-header-search__overlay',
)

const initHeaderSearch = () => {
  headerSearchOverlay.classList.add('closed')
  headerSearchContainer.classList.add('closed')
}

const openHeaderSearch = () => {
  headerSearchOverlay.classList.add('open')
  headerSearchContainer.classList.add('open')
  headerSearchOverlay.classList.remove('closed')
  headerSearchContainer.classList.remove('closed')
  document.body.classList.add('overflow-hidden')
  document.body.classList.add('sidebar-width')
  headerSearchInput.focus()
}
const closeHeaderSearch = () => {
  headerSearchContainer.classList.remove('open')
  headerSearchContainer.classList.add('closed')
  headerSearchInput.value = ''
  headerSearchOverlay.classList.remove('open')
  headerSearchOverlay.classList.add('close')
  document.body.classList.remove('overflow-hidden')
  document.body.classList.remove('sidebar-width')
}
const escapeHeaderSearch = e => {
  if (e.key === 'Escape') {
    headerSearchOverlay.classList.remove('open')
    headerSearchContainer.classList.remove('open')
    headerSearchOverlay.classList.add('closed')
    headerSearchContainer.classList.add('closed')
    headerSearchInput.value = ''
    document.body.classList.remove('overflow-hidden')
    document.body.classList.remove('sidebar-width')
  }
}

const enterHeaderSearch = e => {
  if (e.key === 'Enter' && headerSearchInput.value) {
    openSearchPage(headerSearchInput.value)
    headerSearchInput.value = ''
  }
}

headerSearchButton.addEventListener('click', () => {
  openSearchPage(headerSearchInput.value)
  searchPageInput.value = headerSearchInput.value
  headerSearchInput.value = ''
})

const openSearchPage = searchValue => {
  const query = encodeURIComponent(searchValue)
  window.location.href = constructSearchUri(query)
}

const handleSidebarSearch = e => {
  let searchValue = sidebarSearchInput.value

  if (e.key === 'Enter' && !!searchValue) {
    openSearchPage(searchValue)
    sidebarSearchInput.value = ''
  }

  if (searchValue.length > 0) {
    sidebarSearchClose.classList.add('visible')
  } else {
    sidebarSearchInput.value = ''
    sidebarSearchClose.classList.remove('visible')
  }

  sidebarSearchClose.addEventListener('click', () => {
    sidebarSearchInput.value = ''
    sidebarSearchClose.classList.remove('visible')
  })
}

const handleSidebarSearchButton = () => {
  if (sidebarSearchInput.value) {
    openSearchPage(sidebarSearchInput.value)
  } else {
    sidebarSearchInput.focus()
  }
  sidebarSearchInput.value = ''
}

document.addEventListener('DOMContentLoaded', initHeaderSearch)

openHeaderSearchButton &&
  openHeaderSearchButton.addEventListener('click', openHeaderSearch)

sidebarSearchInput &&
  sidebarSearchInput.addEventListener('keyup', handleSidebarSearch)

closeHeaderSearchButton &&
  closeHeaderSearchButton.addEventListener('click', closeHeaderSearch)

headerSearchInput &&
  headerSearchInput.addEventListener('keyup', enterHeaderSearch)

headerSearchOverlay &&
  headerSearchOverlay.addEventListener('click', closeHeaderSearch)

document.body.addEventListener('keyup', escapeHeaderSearch)

sidebarSearchButton &&
  sidebarSearchButton.addEventListener('click', handleSidebarSearchButton)
