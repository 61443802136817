const breakingNews = document.querySelector('.fp-breaking-news')
const firstBreakingNewsReel = breakingNews?.querySelectorAll(
  '.fp-breaking-news__news-reel',
)[0]
const breakingNewsReels = breakingNews?.querySelectorAll(
  '.fp-breaking-news__news-reel',
)

const breakingNewsReelWidth =
  firstBreakingNewsReel?.getBoundingClientRect().width

const animationSpeed = () => {
  // Get the speed from the breaking News Reel width
  const speed = Math.round(breakingNewsReelWidth / 100)

  // Set the speed to the News Reel throughout animation-duration
  breakingNewsReels?.forEach(reel => {
    reel.style.setProperty('animation-duration', `${speed}s`)
  })
}

breakingNews && document.addEventListener('DOMContentLoaded', animationSpeed)
