const fetchUserLoginStatus = () => {
  // When we login, we have a request header C1-Session-ID. We ask the delivery for the existence of this header.
  // If it exists, then we know we are logged in.
  return fetch('/api/public/user-session')
    .then(res => res.json())
    .catch(err => {
      console.error(err)
      // In case there is a problem with the API call, we assume the user isn't logged in.
      // This will allow the script depending on the emitting of the "logged in" event
      // to run.
      return { isLoggedIn: false }
    })
}

const addBineosScript = () => {
  return fetchUserLoginStatus().then(data => {
    const { isLoggedIn, subscriptions, id } = data

    const dataContainer = document.getElementById('bineos-data')

    if (dataContainer) {
      const bineosUrl = dataContainer.getAttribute('data-bineos-script-url')
      const bineosId = dataContainer.getAttribute('data-bineos-id')
      const touchpoint = dataContainer.getAttribute('data-touchpoint')
      const articleId = dataContainer.getAttribute('data-article-id')
      const customTopicId =
        dataContainer.getAttribute('data-category') +
        dataContainer.getAttribute('data-subcategory')
      const articleBehindPaywall = dataContainer.getAttribute(
        'data-article-behind-paywall',
      )
      const paid = ['true', true].includes(articleBehindPaywall) ? '1' : ''

      let uuid = ''
      let permissions = ''

      if (isLoggedIn) {
        uuid = id

        if (subscriptions) {
          permissions = subscriptions.permissions
            .map(({ permission }) => permission)
            .join(', ')
        }
      }

      const bineosLibraryScript = document.createElement('script')
      bineosLibraryScript.type = 'text/javascript'
      bineosLibraryScript.src = bineosUrl

      const initBineos = () => {
        const bineosInitScript = document.createElement('script')
        bineosInitScript.text = `
          const bineos = new Bineos("${bineosId}");
  
          bineos.dataLayer.touchpoint = "${touchpoint}";
          bineos.dataLayer.articleId = "${articleId}";
          bineos.dataLayer.customTopicId = "${customTopicId}";
          bineos.dataLayer.uuid = "${uuid}";
          bineos.dataLayer.permissions = "${permissions}";
          bineos.dataLayer.paid = "${paid}";
  
          bineos.send();`

        // Append the initialization script after the library script
        document.head.appendChild(bineosInitScript)

        // Remove the data container div
        dataContainer.remove()
      }

      // Append the library script and set up a load event listener
      bineosLibraryScript.addEventListener('load', initBineos)
      document.head.appendChild(bineosLibraryScript)
    }
  })
}

window.addEventListener('DOMContentLoaded', () => {
  const enableSSO = document.documentElement.getAttribute('data-enable-sso')

  const isArticleTemplate = document.documentElement.getAttribute(
    'data-article-template',
  )

  if (
    ['true', true].includes(enableSSO) &&
    ['true', true].includes(isArticleTemplate)
  ) {
    addBineosScript().catch(error => console.error(error))
  }
})
